<template>
  <div>
    <b-alert variant="danger" :show="bankData === undefined">
      <h4 class="alert-heading">Error fetching bank data</h4>
      <div class="alert-body">
        No bank found with this bank id. Check
        <b-link class="alert-link" :to="{ name: 'banks-list' }"> Bank List </b-link>
        for other bank.
      </div>
    </b-alert>
    <div v-if="bankData && Object.keys(bankData).length > 0">
      <b-card no-body class="">
        <div class="m-2">
          <div
            class="d-flex flex-wrap flex-sm-nowrap justify-content-between align-items-center"
          >
            <h3 class="font-weight-bolder text-nowrap mb-0">{{ bankData.name }}</h3>
            <b-button
              variant="primary"
              class="px-3 mobile-w100"
              :to="{ name: 'banks-edit', params: { id: bankData.id } }"
              v-if="ability.can('update', 'bank')"
            >
              <span class="text-nowrap"> <feather-icon icon="EditIcon" /> Edit </span>
            </b-button>
          </div>
        </div>
        <div
          v-for="item of bankData.contents"
          :key="'bankConten' + item.id"
          class="mb-2 p-2 mx-2"
          style="background: #d4f3eb"
        >
          <div class="details-list">
            <div class="title d-flex flex-wrap flex-sm-nowrap justify-content-between">
              <div>{{ item.authorization_type.name }}</div>
              <div>Date Of Being Authorised</div>
              <div>Date Of Being Removed</div>
              <div>Remarks</div>
            </div>
            <div class="mt-md-3 d-flex flex-wrap flex-sm-nowrap justify-content-between">
              <div>{{ item.user && item.user.name }}</div>
              <div>{{ item.date_of_authorised }}</div>
              <div>{{ item.date_of_removed }}</div>
              <div>{{ item.remarks }}</div>
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BAlert,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BCollapse,
  VBToggle,
} from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import router from "@/router";
import store from "@/store";
import bankStoreModule from "../bankStoreModule";
import axiosIns from "@/libs/axios";
import ability from "@/libs/acl/ability";
import draggable from "vuedraggable";

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BCollapse,
    draggable,
    vSelect,
  },
  data() {
    return {
      ability,
    };
  },
  directives: {
    "b-toggle": VBToggle,
  },
  methods: {
    hide() {
      this.$router.replace("/banks");
    },
    makeToast(variant = null, title = null, content = null) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
  },
  setup() {
    const bankData = ref({});

    const BANK_APP_STORE_MODULE_NAME = "app-bank";

    if (!store.hasModule(BANK_APP_STORE_MODULE_NAME))
      store.registerModule(BANK_APP_STORE_MODULE_NAME, bankStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BANK_APP_STORE_MODULE_NAME))
        store.unregisterModule(BANK_APP_STORE_MODULE_NAME);
    });

    const refetchData = () => {
      store
        .dispatch("app-bank/fetchBankWithAuth", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          bankData.value = response.data.bank;
          console.log("bankData", response.data.bank);
        })
        .catch((error) => {
          console.log("error when fetching bank", error);
          if (error.response.status === 404) {
            bankData.value = undefined;
          }
        });
    };

    onMounted(() => {
      refetchData();
    });

    return {
      bankData,
      refetchData,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";
.details-list {
  width: 100%;
  .title {
    color: #1e6998;
    font-weight: bold;
  }
  .d-flex {
    * {
      width: 33.33%;
      height: 30px;
    }
  }
}
@media screen and (max-width: 768px) {
  .details-list {
    display: flex;
    .d-flex {
      &:first-child {
        width: 70%;
      }
      &:last-child {
        width: 30%;
      }
      * {
        width: 100%;
        line-height: 1;
        margin-bottom: 0.5rem;
      }
    }
  }
}
</style>
